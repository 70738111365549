export const HotelIcon = () => (
  <svg
    className="inline-block"
    width="1em"
    height="1em"
    viewBox="0 0 20 21"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_898_3310)">
      <path
        d="M5.9375 9.875C6.12292 9.875 6.30418 9.92998 6.45835 10.033C6.61252 10.136 6.73268 10.2824 6.80364 10.4537C6.8746 10.625 6.89316 10.8135 6.85699 10.9954C6.82081 11.1773 6.73153 11.3443 6.60041 11.4754C6.4693 11.6065 6.30226 11.6958 6.1204 11.732C5.93854 11.7682 5.75004 11.7496 5.57874 11.6786C5.40743 11.6077 5.26101 11.4875 5.158 11.3333C5.05498 11.1792 5 10.9979 5 10.8125C5 10.5639 5.09877 10.3254 5.27459 10.1496C5.4504 9.97377 5.68886 9.875 5.9375 9.875ZM5.9375 8.625C5.50485 8.625 5.08192 8.75329 4.72219 8.99366C4.36246 9.23403 4.08208 9.57567 3.91651 9.97538C3.75095 10.3751 3.70763 10.8149 3.79203 11.2393C3.87644 11.6636 4.08478 12.0534 4.3907 12.3593C4.69663 12.6652 5.08641 12.8736 5.51074 12.958C5.93507 13.0424 6.37491 12.9991 6.77462 12.8335C7.17433 12.6679 7.51598 12.3875 7.75634 12.0278C7.99671 11.6681 8.125 11.2451 8.125 10.8125C8.125 10.2323 7.89453 9.67594 7.4843 9.2657C7.07406 8.85547 6.51766 8.625 5.9375 8.625Z"
        fill="currentColor"
      />
      <path
        d="M15.625 9.25001H10.625C10.2935 9.25001 9.97554 9.38171 9.74112 9.61613C9.5067 9.85055 9.375 10.1685 9.375 10.5V14.25H2.5V7.12501L10 3.08751L17.8313 7.30001L18.4187 6.20001L10.2937 1.82501C10.2033 1.77686 10.1024 1.75168 10 1.75168C9.89755 1.75168 9.79668 1.77686 9.70625 1.82501L1.58125 6.20001C1.48145 6.25315 1.39794 6.33237 1.3396 6.42923C1.28127 6.52608 1.2503 6.63695 1.25 6.75001V19.25H2.5V15.5H17.5V19.25H18.75V12.375C18.75 11.5462 18.4208 10.7514 17.8347 10.1653C17.2487 9.57925 16.4538 9.25001 15.625 9.25001ZM10.625 14.25V10.5H15.625C16.1223 10.5 16.5992 10.6976 16.9508 11.0492C17.3025 11.4008 17.5 11.8777 17.5 12.375V14.25H10.625Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_898_3310">
        <rect
          width="20"
          height="20"
          fill="currentColor"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default HotelIcon;
