import { useCallback } from 'react';
import cn from 'classnames';
import CalendarDay from '../../../../interfaces/planner/CalendarDay';
import CalendarBooking from '../../../../interfaces/planner/CalendarBooking';
import CalendarEvent from '../../../../interfaces/planner/CalendarEvent';
import { usePlannerCalendarContext } from './PlannerContext';
import usePlannerDates from '../../../../hooks/planner/usePlannerDates';

type PlannerCalendarEventsProps = {
  day: CalendarDay;
  maxSlots: number;
};

const PlannerCalendarEvents = ({ day, maxSlots }: PlannerCalendarEventsProps) => {
  const { openDetails } = usePlannerCalendarContext();
  const { firstDay } = usePlannerDates();

  const eventClassName = useCallback(
    (item: CalendarBooking | CalendarEvent) =>
      cn('cursor-pointer py-0 px-2 text-left whitespace-nowrap overflow-hidden text-ellipsis', {
        'bg-primary-lighter border-primary border-dashed': item.type === 'booking',
        'bg-green-do-light border-green-do': item.type === 'booking',
        'bg-yellow-light border-yellow': item.type === 'custom',
        'border-l-4': item.starting,
        '-ml-3': !item.starting,
        '-mr-3': !item.ending,
      }),
    [],
  );

  const showLabel = useCallback(
    (event: CalendarBooking | CalendarEvent) =>
      event.starting || (event.fromDate?.isBefore(firstDay, 'month') && day.date.date() === 1),
    [day, firstDay],
  );

  return Array.from({ length: maxSlots + 1 }).map((_, index) => {
    const event = day.events?.find(event => event.slot === index);
    return event ? (
      <div
        key={event.id}
        title={event.name}
        className={eventClassName(event)}
        onClick={e => openDetails(e, event)}
      >
        {showLabel(event) ? event.name : <>&nbsp;</>}
      </div>
    ) : (
      <div key={index}>&nbsp;</div>
    );
  });
};

export default PlannerCalendarEvents;
