export const ChatIcon = () => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 18 18"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2129_53325)">
        <path
          d="M9.97875 16.875L9 16.3125L11.25 12.375H14.625C14.9234 12.375 15.2095 12.2565 15.4205 12.0455C15.6315 11.8345 15.75 11.5484 15.75 11.25V4.5C15.75 4.20163 15.6315 3.91548 15.4205 3.7045C15.2095 3.49353 14.9234 3.375 14.625 3.375H3.375C3.07663 3.375 2.79048 3.49353 2.5795 3.7045C2.36853 3.91548 2.25 4.20163 2.25 4.5V11.25C2.25 11.5484 2.36853 11.8345 2.5795 12.0455C2.79048 12.2565 3.07663 12.375 3.375 12.375H8.4375V13.5H3.375C2.77826 13.5 2.20597 13.2629 1.78401 12.841C1.36205 12.419 1.125 11.8467 1.125 11.25V4.5C1.125 3.90326 1.36205 3.33097 1.78401 2.90901C2.20597 2.48705 2.77826 2.25 3.375 2.25H14.625C15.2217 2.25 15.794 2.48705 16.216 2.90901C16.6379 3.33097 16.875 3.90326 16.875 4.5V11.25C16.875 11.8467 16.6379 12.419 16.216 12.841C15.794 13.2629 15.2217 13.5 14.625 13.5H11.9025L9.97875 16.875Z"
          fill="currentColor"
        />
        <path d="M13.5 5.625H4.5V6.75H13.5V5.625Z" fill="currentColor" />
        <path d="M10.125 9H4.5V10.125H10.125V9Z" fill="currentColor" />
      </g>
      <defs>
        <clipPath id="clip0_2129_53325">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ChatIcon;
