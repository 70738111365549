import { apiDelete, apiGet, apiPatch, apiPost } from '../axios';
import { ListResponse, PageParams } from '../interfaces';

export interface Participant {
  agency: string;
  country: string;
  is_active: boolean;
  id: string;
  name: string;
  gender: string;
  index_no: string;
  job_description: string;
  duty_station: string;
  cat_c_license: boolean;
  preferred_training_language: string;
  preferred_training_location: string;
  training_request: string;
  updated?: string;
}

const postParticipant = (requestId: string, data: Participant) =>
  apiPost<Participant>(`/digital-office/training-requests/${requestId}/participants/`, data);

const getParticipants = (requestId: string, params: PageParams) =>
  apiGet<ListResponse<Participant>>(
    `/digital-office/training-requests/${requestId}/participants/`,
    params,
  );

const patchParticipant = (requestId: string, data: Participant) =>
  apiPatch<Participant>(
    `/digital-office/training-requests/${requestId}/participants/${data.id}/`,
    data,
  );

const deleteParticipant = (requestId: string, itemId: string) =>
  apiDelete<Participant>(`/digital-office/training-requests/${requestId}/participants/${itemId}/`);

export { postParticipant, getParticipants, patchParticipant, deleteParticipant };
