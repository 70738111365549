import { IconComponent } from '@/common/interfaces';

export const CloseIcon: IconComponent = ({ color = 'currentColor', size = '20' }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path d="M13.5 5.7875L12.7125 5L9 8.7125L5.2875 5L4.5 5.7875L8.2125 9.5L4.5 13.2125L5.2875 14L9 10.2875L12.7125 14L13.5 13.2125L9.7875 9.5L13.5 5.7875Z" />
    </g>
  </svg>
);

export default CloseIcon;
