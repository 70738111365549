import { apiGet, apiPost } from '../axios';

interface ISimpleAgency {
  id: string;
  name: string;
  short_name?: string;
}

const createAgency = (agencyName: string) =>
  apiPost<ISimpleAgency>('/agencies/', {
    name: agencyName,
  });

const getAgencies = () => apiGet<ISimpleAgency[]>('/agencies/');

export { ISimpleAgency, createAgency, getAgencies };
