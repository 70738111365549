import { InputDropdown, InputDropdownItem, InputText } from '@unbooking/ui-kit';
import { FC, useContext, useEffect } from 'react';
import { IRequestTrainingFormStepProps, RequestTrainingStep } from '../common/interfaces';
import BaseStep from './components/BaseStep';
import { RequestTrainingFormContext } from '../common/formContext';
import { nonEmptyValidation } from '../common/FieldValidationWarning';
import { RequiredMark, RequiredUnderline } from '@/components/common';

const STEP = RequestTrainingStep.AGENCY;

export const AgencyStep: FC<IRequestTrainingFormStepProps> = ({
  formData,
  updateFormData,
  goToNextStep,
  goToPreviousStep,
  updateStepCompleted,
}) => {
  const { formContext } = useContext(RequestTrainingFormContext);
  const formStep = formData[STEP];

  const allAgencies = formStep?.other_agencies
    ? formStep?.agencies.concat(formStep?.other_agencies)
    : formStep?.agencies;
  const agencySelectedValidation = nonEmptyValidation(allAgencies);
  const nextStepEnabled = agencySelectedValidation === null;

  const agencyOptions = formContext?.agencies ?? [];

  useEffect(() => {
    updateStepCompleted(STEP, nextStepEnabled);
  }, [nextStepEnabled]);

  return (
    <BaseStep
      goToNextStep={goToNextStep}
      formStep={STEP}
      goToPreviousStep={goToPreviousStep}
      nextStepEnabled={nextStepEnabled}
    >
      <div className="flex flex-col w-full gap-10">
        <div className="flex flex-col">
          <div className="flex flex-col items-stretch mb-7">
            <div className="text-white mb-2">
              Select the agency or organization for which you are requesting this training
              <RequiredMark />
            </div>
            <InputDropdown
              value={formStep?.agencies}
              onChange={e => updateFormData('agencies', e.target.value)}
              items={agencyOptions as InputDropdownItem[]}
              name="agency-select"
              placeholder="Select one or more agencies"
              multiple
              isSearchable
            />
            {agencySelectedValidation && <RequiredUnderline />}
            {/* <FieldValidationWarning result={agencySelectedValidation} /> */}
          </div>
          <div className="text-white mb-2">
            In case the agency or organization is missing from the list, please enter them below
          </div>
          <InputText
            value={formStep?.other_agencies}
            onChange={e => updateFormData('other_agencies', e.target.value)}
            name="other_agencies-input"
            placeholder="Enter missing agencies"
          />
        </div>
      </div>
    </BaseStep>
  );
};

export default AgencyStep;
