import { Routes, Route } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import { useAuth } from '@unbooking/ui-auth';

import { IPage } from '@/common/interfaces';
import PageContentProvider from '../../components/cms/PageContentProvider';
import { DefaultMenuPages, NoMenuPages, DigitalOfficePages, UserMenuPages } from './pages';
import { PageNotFound, RootLayout, SignInPage } from '../../pages';
import { useWafflesContext } from '../providers/WaffleProvider';

export function RoadSafetyRouter() {
  const { isAuthenticated } = useAuth();
  const { flags } = useWafflesContext();

  const pageToRoute = (page: IPage) => {
    // TO DO: Need clear way for closing routes
    if (page.slug === 'planner' && !flags.PLANNER_PAGE.is_active) return null;

    const element = page.shouldLoadContent ? (
      <PageContentProvider page={page} />
    ) : (
      <page.component content={null} />
    );

    return <Route path={page.path} element={element} key={page.path} />;
  };

  return (
    <BrowserRouter
      future={{
        v7_startTransition: true,
        v7_relativeSplatPath: true,
      }}
    >
      <Routes>
        {isAuthenticated ? (
          <>
            <Route element={<RootLayout menu="default" />}>
              {Object.values(DefaultMenuPages).map(pageToRoute)}
              <Route path="*" element={<PageNotFound />} />
            </Route>
            <Route element={<RootLayout menu="none" />}>
              {Object.values(NoMenuPages).map(pageToRoute)}
            </Route>
            <Route element={<RootLayout menu="digital-office" />}>
              {Object.values(DigitalOfficePages).map(pageToRoute)}
            </Route>
            <Route element={<RootLayout menu="user-menu" />}>
              {Object.values(UserMenuPages).map(pageToRoute)}
            </Route>
          </>
        ) : (
          <>
            {/*Used in Sign in flow*/}
            <Route path="/redirect" element={<SignInPage />} />
            <Route path="*" element={<SignInPage />} />
          </>
        )}
      </Routes>
    </BrowserRouter>
  );
}

export default RoadSafetyRouter;
