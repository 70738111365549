import { useCallback } from 'react';
import { Button, InputDropdown, InputDropdownItem, InputLabel } from '@unbooking/ui-kit';
import PlannerFilters from '../../../../enums/PlannerFilters';
import usePlannerFilters from '../../../../hooks/planner/usePlannerFilters';
import { TrainingProgramSelect } from '@/components/inputs/TrainingProgramSelect';
import { useQuery } from '@tanstack/react-query';
import { getDOStatuses } from '@/common/api/digitalOffice';
import { Spinner } from '@/components/common';

const PlannerCalendarFilters = () => {
  const { filters, setFilter, resetFilters } = usePlannerFilters();

  const { isLoading: statusLoading, data: statuses = [] } = useQuery({
    queryKey: ['do-statuses'],
    queryFn: getDOStatuses,
  });

  const onReset = useCallback(() => {
    resetFilters();
  }, [resetFilters]);

  return (
    <div className="flex gap-6">
      <div className="w-full grid grid-cols-4 gap-6">
        <div>
          <InputLabel light fullWidth label="Training type" />
          <TrainingProgramSelect
            selectedProgram={filters?.trainingType ?? ''}
            onProgramSelect={value => setFilter(PlannerFilters.TRAINING_TYPE, value)}
            showLabel={false}
            light
            placeholder="All"
          />
        </div>
        {statusLoading ? (
          <Spinner />
        ) : (
          <div>
            <InputLabel light fullWidth label="Status" />
            <InputDropdown
              value={filters?.state ?? ''}
              onChange={e => setFilter(PlannerFilters.STATE, e.target.value)}
              items={statuses as unknown as InputDropdownItem[]}
              name="status-select"
              placeholder="All"
              light
              fullWidth
            />
          </div>
        )}
      </div>
      <div className="w-[120px] pt-7 text-xs whitespace-nowrap">
        <Button
          plain
          label="Clear all"
          onClick={onReset}
          disabled={Object.keys(filters).length === 0}
        />
      </div>
    </div>
  );
};

export default PlannerCalendarFilters;
