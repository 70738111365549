import useQueryFilters from '../ui/useQueryFilters';
import PlannerFilters from '../../enums/PlannerFilters';
import PlannerParams from '../../interfaces/planner/PlannerParams';

const defaults = {
  year: String(new Date().getFullYear()),
  month: String(new Date().getMonth() + 1),
};

const usePlannerFilters = function () {
  const { filters, setFilter, resetFilters } = useQueryFilters(PlannerFilters, defaults);

  return {
    filters: filters as PlannerParams,
    setFilter,
    resetFilters,
  };
};

export default usePlannerFilters;
