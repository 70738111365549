import { useMemo } from 'react';
import cn from 'classnames';
import PlannerEventModal from './PlannerEventModal';
import PlannerCalendarHead from './PlannerCalendarHead';
import PlannerCalendarBody from './PlannerCalendarBody';
import PlannerCalendarDetail from './PlannerCalendarDetail';
import { usePlannerCalendarContext } from './PlannerContext';

const PlannerCalendar = () => {
  const { anchor, styles, detailsRef, currentEvent, editEvent, closeDetails, closeEditModal } =
    usePlannerCalendarContext();

  const arrowClassName = useMemo(
    () =>
      cn(['absolute w-0 h-0', 'border-t-[10px] border-t-transparent', 'border-b-[10px] border-b-transparent'], {
        'border-r-[15px] border-r-gray-lighter -left-[14px]': anchor.x === 'left',
        'border-l-[15px] border-l-gray-lighter -right-[14px]': anchor.x === 'right',
        'top-1/2 -translate-y-1/2': anchor.y === 'center',
        'top-full -translate-y-full': anchor.y === 'bottom',
      }),
    [anchor]
  );

  const innerArrowClassName = useMemo(
    () =>
      cn(
        [
          'relative w-0 h-0 -translate-y-1/2',
          'border-t-[9px] border-t-transparent',
          'border-b-[9px] border-b-transparent',
        ],
        {
          'border-r-[14px] border-r-white-light left-px': anchor.x === 'left',
          'border-l-[14px] border-l-white-light -left-px -translate-x-full': anchor.x === 'right',
        }
      ),
    [anchor]
  );

  return (
    <div className="bg-white-light -mx-px">
      <div className="flex flex-col w-full text-sm">
        <PlannerCalendarHead />
        <PlannerCalendarBody />
      </div>
      {currentEvent && (
        <>
          <div className="fixed inset-0 bg-transparent z-40" onClick={closeDetails} />
          <div ref={detailsRef} className="fixed animate-fadeIn z-50 w-[360px]" style={styles}>
            <div className="relative">
              <div className={arrowClassName}>
                <div className={innerArrowClassName} />
              </div>
              <PlannerCalendarDetail event={currentEvent} />
            </div>
          </div>
        </>
      )}
      {editEvent && (
        <PlannerEventModal
          open
          onClose={closeEditModal}
          defaultValues={{
            ...editEvent,
            remarks: editEvent.remarks || '',
            organizationName: editEvent.organizationName || '',
            eventStartDate: editEvent.fromDate?.toISOString() || '',
            eventEndDate: editEvent.toDate?.toISOString() || '',
          }}
        />
      )}
    </div>
  );
};

export default PlannerCalendar;
