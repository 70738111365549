export const InProgressIcon = () => (
  <svg
    className="inline-block"
    width="1em"
    height="1em"
    viewBox="0 0 25 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2787_70236)">
      <path
        d="M12.5 1.5C10.4233 1.5 8.39323 2.11581 6.66652 3.26957C4.9398 4.42332 3.59399 6.0632 2.79927 7.98182C2.00455 9.90045 1.79661 12.0116 2.20176 14.0484C2.6069 16.0852 3.60693 17.9562 5.07538 19.4246C6.54383 20.8931 8.41476 21.8931 10.4516 22.2982C12.4884 22.7034 14.5996 22.4955 16.5182 21.7007C18.4368 20.906 20.0767 19.5602 21.2304 17.8335C22.3842 16.1068 23 14.0767 23 12C22.9969 9.21619 21.8896 6.5473 19.9212 4.57884C17.9527 2.61039 15.2838 1.50314 12.5 1.5ZM12.5 21C10.1131 21 7.82387 20.0518 6.13604 18.364C4.44822 16.6761 3.5 14.3869 3.5 12C3.5 9.61305 4.44822 7.32387 6.13604 5.63604C7.82387 3.94821 10.1131 3 12.5 3V12L18.8609 18.361C18.0267 19.1982 17.0353 19.8623 15.9437 20.3151C14.8521 20.768 13.6818 21.0007 12.5 21Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_2787_70236">
        <rect width="24" height="24" fill="currentColor" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);

export default InProgressIcon;
