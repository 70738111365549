import { PIE_CHART_BACKGROUND } from '@/common/constants';
import { InputDropdownItem } from '@unbooking/ui-kit';

export const lightenColor = (color: string, percent: number): string => {
  const hex = color.startsWith('#') ? color.slice(1) : color;
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  const lighten = (channel: number) =>
    Math.min(255, Math.floor(channel + (255 - channel) * percent));
  return `#${[lighten(r), lighten(g), lighten(b)].map(c => c.toString(16).padStart(2, '0')).join('')}`;
};

export const transformData = (data: Record<string, number> = {}) => {
  const sortedData = Object.entries(data).sort(([, countA], [, countB]) => countB - countA);

  return sortedData.map(([label, count], index) => ({
    label,
    color: PIE_CHART_BACKGROUND[index],
    count,
  }));
};

export const mapToOptions = <T extends Record<string, any>>(
  items: T[],
  labelKey: keyof T,
  valueKey: keyof T,
): InputDropdownItem[] =>
  items.map(item => ({
    label: item[labelKey],
    value: item[valueKey],
  }));
