import WafflesProvider from './providers/WaffleProvider';
import RoadSafetyRouter from './router/router';

export function App() {
  return (
    <WafflesProvider>
      <RoadSafetyRouter />
    </WafflesProvider>
  );
}

export default App;
