import { InputDropdownItem, InputLabel, InputDropdown, InputDate, Button } from '@unbooking/ui-kit';
import { TrainingDetails } from './AnalyticsPage';

interface AnalyticsPageFiltersProps {
  filters: Partial<TrainingDetails>;
  extraData?: AnalyticsData;
  updateFilters: (key: string, value: any) => void;
  onDatesSelect: (value: any) => void;
  clearFilters: () => void;
  rerenderKey: string | number;
}

interface AnalyticsData {
  statusesOptions: InputDropdownItem[];
  trainingTypesOptions: InputDropdownItem[];
}

const AnalyticsPageFilters: React.FC<AnalyticsPageFiltersProps> = ({
  filters,
  extraData,
  updateFilters,
  onDatesSelect,
  clearFilters,
  rerenderKey,
}) => {
  return (
    <div className="flex gap-6">
      <div className="w-72">
        <InputLabel light fullWidth label="Training program" />
        <InputDropdown
          value={filters.training_type}
          onChange={event => updateFilters('training_type', event.target.value)}
          items={extraData?.trainingTypesOptions || []}
          name="training-program-select"
          placeholder="All"
          light
          fullWidth
        />
      </div>
      <div>
        <InputLabel light fullWidth label="Proposed event date" />
        <InputDate
          key={rerenderKey}
          name="training-request-dates"
          onChange={e => onDatesSelect(e.target.value)}
          mode="range"
          placeholder="All"
          light
          fullWidth
        />
      </div>
      <div className="w-72">
        <InputLabel light fullWidth label="Status" />
        <InputDropdown
          value={filters.status}
          onChange={event => updateFilters('status', event.target.value)}
          items={extraData?.statusesOptions || []}
          name="status-select"
          placeholder="All"
          light
          fullWidth
        />
      </div>
      <div className="w-[120px] pt-7 text-xs whitespace-nowrap">
        <Button plain label="Clear all" onClick={clearFilters} />
      </div>
    </div>
  );
};

export default AnalyticsPageFilters;
