export const UserIcon = () => (
  <svg
    className="inline-block"
    width="1em"
    height="1em"
    viewBox="0 0 20 21"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_602_29222)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.7362 3.52666C11.2223 3.18328 10.6181 3 10 3C9.1712 3 8.37634 3.32924 7.79029 3.91529C7.20424 4.50134 6.875 5.2962 6.875 6.125C6.875 6.74307 7.05828 7.34725 7.40166 7.86116C7.74504 8.37506 8.2331 8.7756 8.80412 9.01212C9.37514 9.24865 10.0035 9.31053 10.6097 9.18995C11.2159 9.06938 11.7727 8.77175 12.2097 8.33471C12.6467 7.89767 12.9444 7.34085 13.065 6.73466C13.1855 6.12847 13.1236 5.50013 12.8871 4.92911C12.6506 4.3581 12.2501 3.87004 11.7362 3.52666ZM7.56938 2.48732C8.28885 2.00659 9.13471 1.75 10 1.75C11.1603 1.75 12.2731 2.21094 13.0936 3.03141C13.9141 3.85188 14.375 4.96468 14.375 6.125C14.375 6.99029 14.1184 7.83615 13.6377 8.55562C13.1569 9.27508 12.4737 9.83584 11.6742 10.167C10.8748 10.4981 9.99515 10.5847 9.14648 10.4159C8.29781 10.2471 7.51826 9.83045 6.90641 9.21859C6.29456 8.60674 5.87788 7.82719 5.70907 6.97852C5.54026 6.12985 5.6269 5.25019 5.95803 4.45076C6.28916 3.65133 6.84992 2.96805 7.56938 2.48732ZM15 19.25H16.25V16.125C16.25 14.9647 15.7891 13.8519 14.9686 13.0314C14.1481 12.2109 13.0353 11.75 11.875 11.75H8.125C6.96468 11.75 5.85188 12.2109 5.03141 13.0314C4.21094 13.8519 3.75 14.9647 3.75 16.125V19.25H5V16.125C5 15.2962 5.32924 14.5013 5.91529 13.9153C6.50134 13.3292 7.2962 13 8.125 13H11.875C12.2854 13 12.6917 13.0808 13.0709 13.2379C13.45 13.3949 13.7945 13.6251 14.0847 13.9153C14.3749 14.2055 14.6051 14.55 14.7621 14.9291C14.9192 15.3083 15 15.7146 15 16.125V19.25Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_602_29222">
        <rect
          width="20"
          height="20"
          fill="currentColor"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default UserIcon;
