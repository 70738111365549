import cn from 'classnames';
import { useMemo } from 'react';
import CalendarDay from '../../../../interfaces/planner/CalendarDay';
import PlannerCalendarEvents from './PlannerCalendarEvents';
import PlannerCalendarNumber from './PlannerCalendarNumber';

type PlannerCalendarDayProps = {
  day: CalendarDay;
  maxSlots: number;
  index: number;
};

const PlannerCalendarDay = ({ day, maxSlots, index }: PlannerCalendarDayProps) => {
  const dayClassName = useMemo(
    () =>
      cn('w-[14.285%] align-top text-right py-1 px-3 font-normal border-t border-gray-lightest', {
        'border-l': index !== 0,
      }),
    [index],
  );

  return (
    <div className={dayClassName}>
      <div className="min-h-[92px] flex flex-col gap-1">
        <div className="text-right">
          <PlannerCalendarNumber day={day} />
        </div>
        <div className="flex flex-col gap-1">
          <PlannerCalendarEvents day={day} maxSlots={maxSlots} />
        </div>
      </div>
    </div>
  );
};

export default PlannerCalendarDay;
