export const IMAGE_ICON_SIZE = 'width-30';
export const IMAGE_FULL_SIZE = 'original';

export const DATE_RANGE_SEPARATOR = ' - ';
export const DATE_FORMAT_SUBMIT = 'YYYY-MM-DD';
export const DATE_FORMAT_ALT_YEAR = 'DD MMM YYYY';
export const DATE_FORMAT_ALT = 'DD MMM';
export const DATE_FORMAT = 'DD/MM/YYYY';

export const DEBOUNCE_TIME = 300;
export const DEFAULT_LIMIT = 10;
export const DEFAULT_OFFSET = 0;
