import React, { FC, useMemo, useState } from 'react';
import { Button, InputDropdown, InputDropdownItem, InputText, Table } from '@unbooking/ui-kit';
import { AttendeesIcon, CloseIcon, EditIcon, ExcelIcon, SuccessIcon, TrashIcon } from '@icons';
import { v4 as uuidv4 } from 'uuid';
import {
  deleteParticipant,
  getParticipants,
  Participant,
  patchParticipant,
  postParticipant,
} from '@/common/api/participants';
import { useMutation, useQuery } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { TrainingRequestDetail } from '@/common/api/digitalOffice';
import { getAgencies } from '@/common/api/agencies';
import { getCountryTrainings } from '@/common/api/impact';
import Switcher from '@/components/common/Switcher';
import { Spinner } from '@/components/common';
import './Participants.css';

interface IParticipants {
  trainingRequest: TrainingRequestDetail;
}

interface ParticipantExt extends Participant {
  isDraft: boolean;
  isEditing: boolean;
  draft: Partial<Participant>;
}

interface ITextCell {
  field: keyof Participant;
  item: ParticipantExt;
  placeholder: string;
}

interface IDropdownCell {
  field: keyof Participant;
  item: ParticipantExt;
  options: InputDropdownItem[];
  placeholder: string;
}

interface ISwitcherCell {
  field: keyof Participant;
  item: ParticipantExt;
}

const Participants: FC<IParticipants> = ({ trainingRequest }) => {
  const [participants, setParticipants] = useState<ParticipantExt[]>([]);
  const [participantsCount, setParticipantsCount] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [rowLimit, setRowLimit] = useState<number>(10);

  const onOffsetChange = (offset: number) => {
    const page = Math.floor(offset / rowLimit) + 1;
    setPage(page);
  };

  const { isLoading: agencyLoading, data: agencies = [] } = useQuery({
    queryKey: ['agencies'],
    queryFn: getAgencies,
    select: ({ data }) => data.map(({ id, name }) => ({ label: name, value: id })),
  });

  const { isLoading: countryLoading, data: countries = [] } = useQuery({
    queryKey: ['countries'],
    queryFn: getCountryTrainings,
    select: ({ countries }) => countries.map(({ id, name }) => ({ label: name, value: id })),
  });

  const genders = [
    { label: 'Female', value: 'FEMALE' },
    { label: 'Male', value: 'MALE' },
  ];

  const { isFetching, refetch } = useQuery({
    queryKey: ['participants-list', page, rowLimit],
    queryFn: async () => {
      const { data } = await getParticipants(trainingRequest.id, {
        page,
        page_size: rowLimit,
      });
      setParticipantsCount(data.count);
      const defaultParticipants = data.results.map(participant => ({
        ...participant,
        isDraft: false,
        isEditing: false,
        draft: {},
      }));
      setParticipants(defaultParticipants);
      return data;
    },
  });

  const { mutate: postPartisipantMutate, status: postingStatus } = useMutation({
    mutationFn: async (newParticipant: Participant) => {
      const { data } = await postParticipant(trainingRequest.id, newParticipant);
      return data;
    },
    onSuccess: () => {
      toast.success('Participant created successfully!');
      refetch();
    },
  });

  const { mutate: patchParticipantMutate, status: patchingStatus } = useMutation({
    mutationFn: async (updatedData: Participant) => {
      const { data } = await patchParticipant(trainingRequest.id, updatedData);
      return data;
    },
    onSuccess: () => {
      toast.success('Participant updated successfully!');
      refetch();
    },
  });

  const { mutate: deleteParticipantMutate, status: deletingStatus } = useMutation({
    mutationFn: async (participantId: string) => {
      await deleteParticipant(trainingRequest.id, participantId);
    },
    onSuccess: () => {
      toast.success('Participant deleted successfully!');
      refetch();
    },
  });

  const handleAdd = () =>
    setParticipants(prevArray => [
      ...prevArray,
      {
        id: uuidv4(),
        agency: '',
        country: '',
        is_active: true,
        name: '',
        gender: '',
        index_no: '',
        job_description: '',
        duty_station: '',
        cat_c_license: false,
        preferred_training_language: '',
        preferred_training_location: '',
        training_request: trainingRequest.id,
        isDraft: true,
        isEditing: true,
        draft: {},
      },
    ]);

  const handleInputChange = (id: string, field: string, value: string | boolean) => {
    setParticipants(prevArray =>
      prevArray.map(item =>
        item.id === id ? { ...item, draft: { ...item.draft, [field]: value } } : item,
      ),
    );
  };

  const handleEdit = (id: string) => {
    setParticipants(prevArray =>
      prevArray.map(item => (item.id === id ? { ...item, isEditing: true } : item)),
    );
  };

  const handleConfirm = ({ draft, ...rest }: ParticipantExt) => {
    const data = { ...rest, ...draft };
    if (data.isDraft) {
      postPartisipantMutate(data);
    } else {
      patchParticipantMutate(data);
    }
  };

  const handleCancel = (participant: ParticipantExt) => {
    if (participant.isDraft) {
      setParticipants(prevArray => prevArray.filter(item => item.id !== participant.id));
    } else {
      setParticipants(prevArray =>
        prevArray.map(item =>
          item.id === participant.id ? { ...item, draft: {}, isEditing: false } : item,
        ),
      );
    }
  };

  const handleDelete = (participant: ParticipantExt) => {
    if (participant.isDraft) {
      setParticipants(prevArray => prevArray.filter(item => item.id !== participant.id));
    } else {
      deleteParticipantMutate(participant.id);
    }
  };

  const TextCell: FC<ITextCell> = ({ field, item, placeholder }) => {
    const { isEditing, id, draft } = item;
    if (!isEditing) return item[field];
    const value = (draft[field] || item[field]) as string;

    return (
      <InputText
        name={field}
        placeholder={placeholder}
        value={value}
        onChange={e => handleInputChange(id, field, e.target.value)}
        light
      />
    );
  };

  const DropdownCell: FC<IDropdownCell> = ({ field, item, options, placeholder }) => {
    const { isEditing, id, draft } = item;
    if (!isEditing) return options.find(({ value }) => value === item[field])?.label || '-';
    const value = (draft[field] || item[field]) as string;

    return (
      <InputDropdown
        value={value}
        onChange={e => handleInputChange(id, field, e.target.value)}
        items={options}
        name={field}
        placeholder={placeholder}
        light
      />
    );
  };

  const SwitcherCell: FC<ISwitcherCell> = ({ field, item }) => {
    const { isEditing, id, draft } = item;
    if (!isEditing) return item[field] ? 'Yes' : 'No';
    const value = draft[field] === undefined ? (item[field] as boolean) : (draft[field] as boolean);

    return (
      <div className="inline-flex items-center gap-4">
        <Switcher value={value} onChange={value => handleInputChange(id, field, value)} />
      </div>
    );
  };

  const participantsColumns = useMemo(
    () => [
      {
        label: 'Name',
        field: 'name',
        render: (item: ParticipantExt) => <TextCell field="name" item={item} placeholder="Name" />,
      },
      {
        label: 'Gender',
        field: 'gender',
        render: (item: ParticipantExt) => (
          <DropdownCell field="gender" item={item} options={genders} placeholder="Gender" />
        ),
      },
      {
        label: 'Index #',
        field: 'index_no',
        render: (item: ParticipantExt) => (
          <TextCell field="index_no" item={item} placeholder="Index #" />
        ),
      },
      {
        label: 'Description',
        field: 'job_description',
        render: (item: ParticipantExt) => (
          <TextCell field="job_description" item={item} placeholder="Description" />
        ),
      },
      {
        label: 'Country',
        field: 'country',
        render: (item: ParticipantExt) => (
          <DropdownCell field="country" item={item} options={countries} placeholder="Country" />
        ),
      },
      {
        label: 'Agency',
        field: 'agency',
        render: (item: ParticipantExt) => (
          <DropdownCell field="agency" item={item} options={agencies} placeholder="Agency" />
        ),
      },
      {
        label: 'Duty Station',
        field: 'duty_station',
        render: (item: ParticipantExt) => (
          <TextCell field="duty_station" item={item} placeholder="Duty station" />
        ),
      },
      {
        label: 'Cat-C License',
        field: 'cat_c_license',
        render: (item: ParticipantExt) => <SwitcherCell field="cat_c_license" item={item} />,
      },
      {
        label: 'Pref. Language',
        field: 'preferred_training_language',
        render: (item: ParticipantExt) => (
          <TextCell field="preferred_training_language" item={item} placeholder="Pref. language" />
        ),
      },
      {
        label: 'Pref. Location',
        field: 'preferred_training_location',
        render: (item: ParticipantExt) => (
          <TextCell field="preferred_training_location" item={item} placeholder="Pref. location" />
        ),
      },
      {
        label: 'Actions',
        field: 'actions',
        render: (item: ParticipantExt) =>
          item.isEditing ? (
            <div className="flex gap-3">
              <button title="Cancel" onClick={() => handleCancel(item)}>
                <CloseIcon />
              </button>
              <button title="Confirm" onClick={() => handleConfirm(item)}>
                <SuccessIcon />
              </button>
            </div>
          ) : (
            <div className="flex gap-3">
              <button title="Edit" onClick={() => handleEdit(item.id)}>
                <EditIcon />
              </button>
              <button title="Delete" onClick={() => handleDelete(item)}>
                <TrashIcon />
              </button>
            </div>
          ),
      },
    ],
    [countries, agencies],
  );

  const isLoading =
    agencyLoading ||
    countryLoading ||
    isFetching ||
    postingStatus === 'pending' ||
    patchingStatus === 'pending' ||
    deletingStatus === 'pending';

  return (
    <section>
      {isLoading && <Spinner fullScreen />}
      <div>
        <div className="pb-2 flex justify-between items-center">
          <div className="flex gap-3 items-center">
            <AttendeesIcon />
            Participants <span className="text-oslo-gray">{participantsCount} item(s)</span>
          </div>
          <Button label="Import Excel" startIcon={<ExcelIcon color="white" />} />
        </div>
        <div className="participant-table border-b border-gray-lighter">
          <Table
            columns={participantsColumns}
            items={participants}
            count={participantsCount}
            limit={rowLimit}
            offset={rowLimit * (page - 1)}
            onChangeLimit={setRowLimit}
            onChangeOffset={onOffsetChange}
            light
          />
        </div>
      </div>
      <div className="flex justify-start pt-2">
        <Button plain label="+ Add Participant" onClick={handleAdd} />
      </div>
    </section>
  );
};

export default Participants;
