export const SearchIcon = () => (
  <svg
    className="inline-block"
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2128_43709)">
      <path
        d="M16.3129 15.5171L12.0648 11.269C13.0856 10.0435 13.5946 8.47169 13.486 6.88047C13.3774 5.28924 12.6595 3.80115 11.4816 2.72576C10.3038 1.65037 8.75665 1.07047 7.16213 1.1067C5.56761 1.14294 4.04845 1.79251 2.92066 2.9203C1.79288 4.04808 1.1433 5.56725 1.10707 7.16177C1.07084 8.75628 1.65073 10.3034 2.72612 11.4812C3.80152 12.6591 5.28961 13.377 6.88083 13.4856C8.47206 13.5943 10.0439 13.0852 11.2694 12.0645L15.5174 16.3125L16.3129 15.5171ZM2.25038 7.31251C2.25038 6.31124 2.54729 5.33246 3.10356 4.49994C3.65984 3.66741 4.45049 3.01854 5.37554 2.63537C6.30059 2.2522 7.31849 2.15195 8.30052 2.34729C9.28255 2.54262 10.1846 3.02478 10.8926 3.73278C11.6006 4.44079 12.0828 5.34284 12.2781 6.32487C12.4734 7.3069 12.3732 8.32479 11.99 9.24985C11.6068 10.1749 10.958 10.9656 10.1255 11.5218C9.29293 12.0781 8.31415 12.375 7.31288 12.375C5.97068 12.3735 4.68387 11.8397 3.73479 10.8906C2.78571 9.94151 2.25187 8.65471 2.25038 7.31251Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_2128_43709">
        <rect width="18" height="18" fill="currentColor" />
      </clipPath>
    </defs>
  </svg>
);

export default SearchIcon;
