import { useMemo } from 'react';
import { Button, Field, Form, InputDate, InputText } from '@unbooking/ui-kit';
import PlannerEventModalSchema from './PlannerEventModal.schema';
import CalendarEventBody from '../../../../interfaces/planner/CelandarEventBody';
import dayjs from 'dayjs';
import { DATE_FORMAT_SUBMIT } from '../../../../constants/generic';
import { CloseIcon } from '@/assets/icons';
import { postEvent } from '@/common/api/events';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';

type PlannerEventModalProps = {
  open: boolean;
  onClose: () => void;
  defaultValues: CalendarEventBody;
};

const PlannerEventModal = ({ open, defaultValues, onClose }: PlannerEventModalProps) => {
  const schema = useMemo(() => PlannerEventModalSchema(), []);

  const postEventMutation = useMutation({
    mutationFn: postEvent,
    onSuccess: async () => {
      toast.success('Status successfully changed');
      onClose();
    },
  });

  const handleSubmit = (values: CalendarEventBody) => {
    postEventMutation.mutate({
      id: values.id,
      name: values.name,
      eventStartDate: dayjs(values.eventStartDate).format(DATE_FORMAT_SUBMIT),
      eventEndDate: dayjs(values.eventEndDate).format(DATE_FORMAT_SUBMIT),
      remarks: values.remarks,
      organizationName: values.organizationName,
    });
  };

  return (
    <div className="fixed inset-0 w-screen max-h-screen bg-black bg-opacity-50 z-30 flex justify-center items-center">
      <Form defaultValues={defaultValues} onSubmit={handleSubmit} schema={schema}>
        {({ formState: { errors } }) => (
          <section className="w-[560px] bg-slate-100 shadow border-t-2 flex flex-col max-h-screen">
            <div className="h-8 px-4 py-px flex justify-end items-end">
              <div className="cursor-pointer text-xl text-black-light" onClick={onClose}>
                <CloseIcon />
              </div>
            </div>
            <div className="px-4 text-2xl">{defaultValues.id ? 'Edit event' : 'New event'}</div>
            <div className="px-4 py-6 text-sm">
              <div className="mb-8">
                <Field light fullWidth as={InputText} name="name" />
              </div>
              <div className="mb-8 flex gap-4 items-stretch">
                <Field light fullWidth as={InputDate} name="eventStartDate" />
                <Field light fullWidth as={InputDate} name="eventEndDate" />
              </div>
              <div className="mb-8">
                <Field light fullWidth as={InputText} name="remarks" />
              </div>
              <div className="mb-8">
                <Field light fullWidth as={InputText} name="organizationName" />
              </div>
            </div>
            <div className="flex gap-4 items-stretch px-4 py-3 border-t border-gray-light">
              <Button
                outlined
                fullWidth
                type="button"
                label="Cancel"
                color="black"
                onClick={onClose}
              />
              <Button
                fullWidth
                type="submit"
                label={defaultValues.id ? 'Update' : 'Add event'}
                color="primary"
                disabled={Object.keys(errors).length > 0}
              />
            </div>
          </section>
        )}
      </Form>
    </div>
  );
};

export default PlannerEventModal;
