import React, { FC } from 'react';
import { useUser } from '@/common/hooks';
import { Link } from 'react-router-dom';
import { PATHS } from '@/common/path';
import { Button } from '@unbooking/ui-kit';
import { CalendarOptionIcon, MenuIcon } from '@icons';
import SignInBg from '@/assets/img/sign_in_bg.png';

export const UserProfilePage: FC = () => {
  return (
    <div
      style={{ backgroundImage: `url(${SignInBg})` }}
      className="flex flex-1 bg-no-repeat bg-cover"
    >
      <div className="flex flex-1 overflow-y-auto bg-gradient-to-r from-black to-transparent">
        <div
          className="container mx-auto w-full h-max backdrop-blur-xs text-white p-10 mt-12"
          data-testid="home-container"
        >
          <div className="w-[55%] min-w-[min(700px,100%)] flex flex-col gap-10 xl:gap-16">
            <h1 className="text-5xl leading-normal">
              Welcome to your <span className="font-bold">Road Safety Academy</span> Client hub
            </h1>
            <UserProfileInfo />
          </div>
        </div>
      </div>
    </div>
  );
};

export const UserProfileInfo: FC = () => {
  const { ongoing_requests_count = 0 } = useUser();

  return (
    <div className="flex flex-col justify-start gap-8">
      <div className="flex gap-4">
        <Link to={PATHS.userTrainingRequests}>
          <Button label="View all your requests" startIcon={<MenuIcon />} />
        </Link>
        <Link to={PATHS.requestTraining}>
          <Button label="Create a new training request" startIcon={<CalendarOptionIcon />} />
        </Link>
      </div>

      <div className="flex flex-row">
        <div className="flex flex-col bg-white text-black font-thin p-4 gap-3">
          <div className="text-5xl">{ongoing_requests_count}</div>
          <div className="font-bold mb-8">Ongoing requests</div>
          <Link className="text-blue-400 font-medium" to={PATHS.userOngoingTrainingRequests}>
            <Button label="View all ongoing requests" startIcon={<MenuIcon />} />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default UserProfilePage;
