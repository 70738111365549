import { FC } from 'react';
import PlannerProvider from './components/PlannerContext';
import PlannerHeader from './components/PlannerHeader';
import PlannerLegend from './components/PlannerLegend';
import PlannerTopBar from './components/PlannerTopBar';
import PlannerCalendar from './components/PlannerCalendar';

export const PlannerPage: FC = () => {
  return (
    <div className="w-full">
      <PlannerProvider>
        <div className="bg-catskill-white m-5 p-5">
          <div className="flex justify-between mb-6">
            <PlannerHeader />
            <PlannerLegend />
          </div>
          <PlannerTopBar />
          <PlannerCalendar />
        </div>
      </PlannerProvider>
    </div>
  );
};

export default PlannerPage;
