import dayjs from 'dayjs';
import { DATE_FORMAT_SUBMIT } from '../../constants/generic';
import usePlannerDates from './usePlannerDates';
import CalendarEvent from '../../interfaces/planner/CalendarEvent';
import { useQuery } from '@tanstack/react-query';
import { getEvents } from '@/common/api/events';
import usePlannerFilters from './usePlannerFilters';
import CalendarEventsItem from '@/interfaces/planner/CalendarEventsResponse';

const parseEvent = function (event: CalendarEventsItem): CalendarEvent {
  const fromDate = dayjs(event.event_start_date, DATE_FORMAT_SUBMIT);
  const toDate = dayjs(event.event_end_date, DATE_FORMAT_SUBMIT);

  return {
    type: 'custom',
    id: event.id,
    name: event.name,
    remarks: event.remarks,
    organizationName: event.organization_name,
    fromDate,
    toDate,
  };
};

const usePlannerEvents = function () {
  const {
    filters: { trainingType, state, search },
  } = usePlannerFilters();
  const { firstOfMonth, lastOfMonth } = usePlannerDates();

  const { data: events = [] } = useQuery({
    queryKey: ['events', firstOfMonth, lastOfMonth, trainingType, state, search],
    queryFn: () =>
      getEvents({
        eventStartDate: firstOfMonth.toISOString(),
        eventEndDate: lastOfMonth.toISOString(),
        limit: 100,
        trainingType,
        state,
        search,
      }),
    select: ({ data }) => data.results,
  });

  // TO DO: Use real data. Check CalendarEventsItem
  const dummyEventArray = [
    {
      id: '73361037-ed78-4f57-b166-68d8e540c0f2',
      name: 'Test event for Planner',
      event_start_date: '2024-12-06',
      event_end_date: '2024-12-21',
      remarks:
        'Its just dummy data. Please find this text in code and use real endpoint for getting events',
      organization_name: 'WFP',
    },
  ];

  // TO DO: Need check response
  return dummyEventArray.map(parseEvent);
  // return events.map(parseEvent);
};

export default usePlannerEvents;
