import { IconComponent } from '@/common/interfaces';

export const EditIcon: IconComponent = ({ color = 'currentColor', size = '20' }) => {
  return (
    <svg
      data-testid="edit-icon"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 25 23.47"
      fill={color}
    >
      <path
        d="M23.83,23.67H1.17a1.17,1.17,0,0,1,0-2.34H23.83a1.17,1.17,0,0,1,0,2.34M19.08,6.3,15.78,3,18.25.54a1.16,1.16,0,0,1,1.65,0l1.65,1.65a1.15,1.15,0,0,1,0,1.64ZM8.35,17l-3.3-3.29L15,3.83l3.3,3.29ZM3.12,18.92l1.1-4.39,3.3,3.3Z"
        transform="translate(0 -0.2)"
        fillRule="evenodd"
      />
    </svg>
  );
};

export default EditIcon;
