export const ImpactIcon = () => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 300 300"
    xmlSpace="preserve"
    fill="currentColor"
    height="25px"
  >
    <path
      d="M148.77,248.01c54.04,0,98.01-43.97,98.01-98.01s-43.97-98.01-98.01-98.01S50.76,95.96,50.76,150
	S94.73,248.01,148.77,248.01z M131.57,71.36c-5.16,4.35-9.86,10.69-13.87,18.84c-1.2,2.44-2.31,5.02-3.34,7.69H87.47
	C98.69,84.72,114.07,75.19,131.57,71.36z M210.07,97.9h-26.89c-1.03-2.67-2.14-5.25-3.34-7.69c-4-8.15-8.71-14.49-13.87-18.84
	C183.47,75.19,198.85,84.72,210.07,97.9z M229.21,147.5h-37.2c-0.23-16.1-2.66-31.45-7.02-44.6H214
	C223.14,115.52,228.7,130.88,229.21,147.5z M165.98,228.64c5.16-4.35,9.86-10.69,13.87-18.84c1.6-3.25,3.04-6.73,4.34-10.38h28.07
	C200.89,213.99,184.64,224.56,165.98,228.64z M85.28,199.42h28.07c1.3,3.65,2.74,7.13,4.34,10.38c4,8.15,8.71,14.49,13.87,18.84
	C112.9,224.56,96.65,213.99,85.28,199.42z M68.33,152.5h37.2c0.21,15.02,2.35,29.38,6.18,41.92H81.67
	C73.64,182.33,68.8,167.96,68.33,152.5z M187.01,152.5c-0.22,15.12-2.44,29.52-6.41,41.92h-63.66c-3.97-12.4-6.19-26.79-6.41-41.92
	H187.01z M110.53,147.5c0.24-16.23,2.78-31.62,7.31-44.6h61.85c4.53,12.98,7.08,28.37,7.31,44.6H110.53z M122.19,207.59
	c-1.27-2.58-2.43-5.32-3.51-8.18h60.18c-1.07,2.85-2.24,5.59-3.51,8.18c-7.26,14.78-16.7,22.91-26.58,22.91
	S129.44,222.37,122.19,207.59z M185.83,194.42c3.83-12.54,5.96-26.9,6.18-41.92h37.2c-0.47,15.46-5.31,29.83-13.34,41.92H185.83z
	 M175.36,92.41c0.87,1.76,1.68,3.6,2.46,5.49h-58.08c0.77-1.89,1.59-3.73,2.46-5.49c7.26-14.78,16.7-22.91,26.58-22.91
	S168.1,77.63,175.36,92.41z M112.56,102.9c-4.36,13.16-6.79,28.51-7.02,44.6h-37.2c0.51-16.62,6.07-31.98,15.21-44.6H112.56z"
    />
  </svg>
);

export default ImpactIcon;
