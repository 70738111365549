import { FC, useContext, useEffect, useState } from 'react';
import { InputDate, InputDropdown, InputDropdownItem, InputText } from '@unbooking/ui-kit';
import dayjs from 'dayjs';

import { IRequestTrainingFormStepProps, RequestTrainingStep } from '../common/interfaces';
import BaseStep from './components/BaseStep';
import { RequestTrainingFormContext } from '../common/formContext';
import { nonEmptyValidation } from '../common/FieldValidationWarning';
import { rangeDateDisplay } from '@/common/utils';
import { RANGE_SEPARATOR, WEEKEND_DAY_OPTIONS, CUSTOM_TIME_SLOTS } from '@/common/constants';
import { RequiredMark, RequiredUnderline } from '@/components/common';

const STEP = RequestTrainingStep.TIME_AND_LOCATION;

const today = new Date();

export const TimeAndLocationStep: FC<IRequestTrainingFormStepProps> = ({
  formData,
  updateFormData,
  goToNextStep,
  goToPreviousStep,
  updateStepCompleted,
}) => {
  const { formContext } = useContext(RequestTrainingFormContext);
  const formStep = formData[STEP];

  const [selectedDates, setSelectedDates] = useState<string>(
    formStep.start_date && formStep.end_date
      ? rangeDateDisplay(formStep.start_date, formStep.end_date)
      : '',
  );

  const onTrainingSelectedDate = (value: string) => {
    if (!value) {
      setSelectedDates('');
      updateFormData('start_date', '');
      updateFormData('end_date', '');
      return;
    }
    const [startDate, endDate] = value.split(RANGE_SEPARATOR);
    const startDateJS = dayjs(startDate),
      endDateJS = dayjs(endDate);

    setSelectedDates(value);
    updateFormData('start_date', startDateJS.format('YYYY-MM-DD'));
    updateFormData('end_date', endDateJS.format('YYYY-MM-DD'));
  };

  const selectedDatesValidation = nonEmptyValidation(selectedDates);
  const weekendTrainingDaysValidation = nonEmptyValidation(formStep.weekend_training_days);
  const countryLocationValidation = nonEmptyValidation(formStep.country_location);
  const preferredPlaceValidation = nonEmptyValidation(formStep.preferred_place);
  // const conesAvailableValidation = nonEmptyValidation(formStep.cones_available);
  // const projectorAvailableValidation = nonEmptyValidation(formStep.projector_available);
  const coWorkingDaysValidation = nonEmptyValidation(formStep.co_working_days);

  const performValidation = () => {
    const requiredValidations = [
      selectedDatesValidation,
      weekendTrainingDaysValidation,
      countryLocationValidation,
      preferredPlaceValidation,
      coWorkingDaysValidation,
    ];
    return !requiredValidations.some(v => v !== null);
  };
  const nextStepEnabled = performValidation();

  const countryOptions = formContext?.countries ?? [];

  useEffect(() => {
    updateStepCompleted(STEP, nextStepEnabled);
  }, [nextStepEnabled]);

  return (
    <BaseStep
      goToNextStep={goToNextStep}
      formStep={STEP}
      goToPreviousStep={goToPreviousStep}
      nextStepEnabled={nextStepEnabled}
    >
      <div className="flex flex-col w-full gap-10">
        <div className="flex flex-col items-stretch">
          <div className="text-white mb-2">
            State the preferred period when the training could take place (please, note that dates
            are just for reference, the Academy will confirm when the training can take place.)
            <RequiredMark />
          </div>
          <InputDate
            value={selectedDates}
            onChange={e => onTrainingSelectedDate(e.target.value)}
            mode="range"
            name="training-months"
            placeholder="Select one or more months"
            min={today}
            timeSlots={CUSTOM_TIME_SLOTS}
          />
          {selectedDatesValidation && <RequiredUnderline />}
          {/* <FieldValidationWarning result={selectedDatesValidation} /> */}
        </div>
        <div className="flex flex-col items-stretch">
          <div className="text-white mb-2">
            Specify the reason for the requested period and additional information <i>(optional)</i>
          </div>
          <InputText
            value={formStep.reason}
            onChange={e => updateFormData('reason', e.target.value)}
            name="training-reason"
            placeholder="..."
          />
        </div>
        <div>
          <div className="flex flex-col items-stretch">
            <div className="text-white mb-2">
              Our programs are based on a 6 full day working week, please indicate which day of the
              week training should NOT take place
              <RequiredMark />
            </div>
            <InputDropdown
              value={formStep.weekend_training_days as string}
              onChange={e => updateFormData('weekend_training_days', e.target.value)}
              items={WEEKEND_DAY_OPTIONS}
              name="weekend-days-select"
              placeholder="Select the day"
            />
            {weekendTrainingDaysValidation && <RequiredUnderline />}
            {/* <FieldValidationWarning result={weekendTrainingDaysValidation} /> */}
          </div>
        </div>
        <div className="flex flex-col items-stretch">
          <div className="text-white mb-2">
            Please, select the country where you would like the training to take place
            <RequiredMark />
          </div>
          <InputDropdown
            value={formStep.country_location}
            onChange={e => updateFormData('country_location', e.target.value)}
            items={countryOptions as InputDropdownItem[]}
            name="country-location-select"
            placeholder="Select the country"
            isSearchable
          />
          {countryLocationValidation && <RequiredUnderline />}
          {/* <FieldValidationWarning result={countryLocationValidation} /> */}
        </div>
        <div className="flex flex-col items-stretch">
          <div className="text-white mb-2">
            Which are the preferred 'in country' locations for the training to take place?
            <RequiredMark />
          </div>
          <InputText
            value={formStep.preferred_place}
            onChange={e => updateFormData('preferred_place', e.target.value)}
            name="preferred-place"
            placeholder="..."
          />
          {preferredPlaceValidation && <RequiredUnderline />}
          {/* <FieldValidationWarning result={preferredPlaceValidation} /> */}
        </div>
        <div className="flex flex-col items-stretch gap-2">
          <div className="text-white">
            What is the working week of the Country Office (e.g. Monday to Friday or Saturday to
            Thursday)?
            <RequiredMark />
          </div>
          <div className="flex flex-col mb-4">
            <InputText
              value={formStep.co_working_days}
              onChange={e => updateFormData('co_working_days', e.target.value)}
              name="co-working-days"
              placeholder="..."
            />
            {coWorkingDaysValidation && <RequiredUnderline />}
            {/* <FieldValidationWarning result={coWorkingDaysValidation} /> */}
          </div>
          <div className="text-white mt-1">
            What is the working hours of the Country Office (e.g. 09:00 AM - 4:30 PM)?{' '}
            <i>(optional)</i>
          </div>
          <InputText
            value={formStep.co_working_hours}
            onChange={e => updateFormData('co_working_hours', e.target.value)}
            name="co-working-hours"
            placeholder="..."
          />
        </div>
      </div>
    </BaseStep>
  );
};

export default TimeAndLocationStep;
