import { FC } from 'react';

interface ISwitcher {
  onChange: (value: boolean) => void;
  value: boolean;
  label?: string;
}

export const Switcher: FC<ISwitcher> = ({ onChange, value, label = '' }) => {
  return (
    <label className="inline-flex items-center cursor-pointer">
      <input
        type="checkbox"
        onChange={e => onChange(e.target.checked)}
        checked={value}
        className="peer absolute -z-10 opacity-0 hidden"
      />
      <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue dark:peer-focus:ring-blue rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue"></div>
      <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">{label}</span>
    </label>
  );
};

export default Switcher;
