export const getValueOrNone = function (value?: string | number | null) {
  return typeof value === 'string'
    ? value
      ? String(value)
      : 'N/A'
    : typeof value !== 'undefined' && value !== null
    ? String(value)
    : 'N/A';
};

export const capitalizeFirst = function (value: string) {
  return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
};

export const group = function <T>(array: T[], chunkSize: number): T[][] {
  const chunks = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    chunks.push(array.slice(i, i + chunkSize));
  }
  return chunks;
};

export const deduplicate = function <T extends { id: string }>(array: T[]): T[] {
  const seen = new Set<string>();
  return array.filter((item) => {
    if (seen.has(item.id)) {
      return false;
    } else {
      seen.add(item.id);
      return true;
    }
  });
};
