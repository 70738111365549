import { IconComponent } from '@/common/interfaces';

export const ExcelIcon: IconComponent = ({ color = 'currentColor', size = '20' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    width={size}
    height={size}
    viewBox="0 0 512 512"
    fill={color}
  >
    <g>
      <g>
        <path
          d="M468 117.499 357.944 8H115.581C98.792 8 85 20.858 85 37.646v156.6c0-.03 1.023-.246 1.703-.246H100V37.646C100 29.265 107.2 23 115.581 23h235.583L356 27.535v61.593C356 105.847 369.596 119 386.315 119h61.593l4.092 4.795v349.553c0 8.381-5.822 15.652-14.203 15.652H115.581C107.2 489 100 481.728 100 473.348V413H86.703c-.68 0-1.703.037-1.703.007v60.34C85 490.136 98.792 504 115.581 504h322.217C454.586 504 468 490.136 468 473.348zM386.315 104C377.934 104 371 97.509 371 89.128V43.941L431.502 104z"
          opacity="1"
        ></path>
        <path
          d="M373.602 209H74.398C57.61 209 44 222.61 44 239.398v127.204C44 383.39 57.61 397 74.398 397h299.204C390.39 397 404 383.39 404 366.602V239.398C404 222.61 390.39 209 373.602 209zM164.046 353l-18.785-30.122L126.673 353H97.21l31.968-49.12L99.122 257h28.87l17.401 29.793L162.135 257h29.727l-30.518 48.923L194.168 353zM268 353h-63v-96h26v75h37zm40.495-65.437c1.955 1.494 7.261 4.218 15.918 8.173 8.306 3.735 14.073 7.745 17.303 12.029s4.845 9.679 4.845 16.182c0 5.977-1.517 11.282-4.548 15.918-3.032 4.637-7.405 8.239-13.117 10.81-5.713 2.571-12.414 3.856-20.104 3.856-6.416 0-11.832-.451-16.181-1.352-4.351-.899-8.611-2.472-13.611-4.713v-23.201a78.542 78.542 0 0 0 15.522 5.965c5.36 1.429 10.299 2.143 14.781 2.143 3.866 0 6.708-.67 8.511-2.011 1.801-1.34 2.706-3.064 2.706-5.174 0-1.318-.36-2.472-1.086-3.461-.725-.988-1.889-1.987-3.492-2.999-1.604-1.01-5.876-3.075-12.82-6.195-6.284-2.856-10.997-5.624-14.137-8.306-3.143-2.68-5.471-5.756-6.987-9.228-1.516-3.471-2.273-7.58-2.273-12.326 0-8.875 3.23-15.796 9.689-20.763 6.46-4.965 15.336-7.448 26.629-7.448 9.975 0 20.147 2.307 30.519 6.92l-7.976 20.104c-9.009-4.13-16.786-6.196-23.334-6.196-3.384 0-5.844.593-7.382 1.779-1.539 1.187-2.307 2.659-2.307 4.417-.001 1.892.977 3.583 2.932 5.077z"
          opacity="1"
        ></path>
      </g>
    </g>
  </svg>
);

export default ExcelIcon;
