import dayjs from 'dayjs';
import { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { Button, InputDropdown, InputDropdownItem, InputText } from '@unbooking/ui-kit';
import usePlannerFilters from '../../../../hooks/planner/usePlannerFilters';
import PlannerFilters from '../../../../enums/PlannerFilters';
import { months } from '../../../../constants/dates';
import { usePlannerCalendarContext } from './PlannerContext';
import PlannerCalendarFilters from './PlannerCalendarFilters';
import cn from 'classnames';
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  CalendarAddIcon,
  ChevronRightIcon,
  SearchIcon,
} from '@/assets/icons';

const PlannerTopBar = () => {
  const { filters, setFilter } = usePlannerFilters();
  const { openEditModal } = usePlannerCalendarContext();

  const [showFilters, setShowFilters] = useState(false);
  const [showSearch, setShowSearch] = useState(false);

  const onChangeField = useCallback(
    (name: PlannerFilters) => (e: ChangeEvent<any>) => {
      const value = e.target.value || undefined;
      setFilter(name, value);
    },
    [setFilter],
  );

  const onClickToday = useCallback(() => {
    setFilter(PlannerFilters.YEAR, String(new Date().getFullYear()));
    setFilter(PlannerFilters.MONTH, String(new Date().getMonth() + 1));
  }, [setFilter]);

  const currentDate = useMemo(() => {
    return dayjs(`${filters.year}-${filters.month}-01`);
  }, [filters.year, filters.month]);

  const onClickPrev = useCallback(() => {
    const prevDate = currentDate.subtract(1, 'month');
    setFilter(PlannerFilters.YEAR, prevDate.year());
    setFilter(PlannerFilters.MONTH, prevDate.month() + 1);
  }, [currentDate, setFilter]);

  const onClickNext = useCallback(() => {
    const nextDate = currentDate.add(1, 'month');
    setFilter(PlannerFilters.YEAR, nextDate.year());
    setFilter(PlannerFilters.MONTH, nextDate.month() + 1);
  }, [currentDate, setFilter]);

  const onClickFilters = useCallback(() => {
    setShowFilters(prev => !prev);
  }, [setShowFilters]);

  const onClickSearch = useCallback(() => {
    setShowSearch(true);
  }, [setShowSearch]);

  const onBlurSearch = useCallback(() => {
    setShowSearch(false);
  }, [setShowSearch]);

  const onClickAdd = useCallback(() => {
    openEditModal({
      id: '',
      name: '',
      remarks: '',
      organizationName: '',
      type: 'custom',
    });
  }, [openEditModal]);

  const renderInput = useCallback(
    (item?: InputDropdownItem | InputDropdownItem[]) =>
      Array.isArray(item) ? <strong>{item[0]?.label}</strong> : <strong>{item?.label}</strong>,
    [],
  );

  const filtersLabel = useMemo(() => {
    const filtersCount = Object.keys(filters).filter(
      item =>
        ![PlannerFilters.YEAR, PlannerFilters.MONTH, PlannerFilters.SEARCH].includes(
          item as PlannerFilters,
        ),
    ).length;

    const filtersCountString = filtersCount ? ` (${filtersCount})` : '';
    const filtersLabelPrefix = showFilters ? `Hide filters` : 'Filters';
    return filtersLabelPrefix + filtersCountString;
  }, [filters, showFilters]);

  const filtersIconClassName = useMemo(
    () =>
      cn('inline-block -mr-2', {
        'rotate-90': !showFilters,
        '-rotate-90': showFilters,
      }),
    [showFilters],
  );

  const years = useMemo(() => {
    const currentYear = dayjs().year();
    const items = [];

    for (let i = currentYear - 1; i <= currentYear + 3; i++) {
      items.push({ label: String(i), value: String(i) });
    }

    return items;
  }, []);

  return (
    <div className="bg-white-light py-3 px-4">
      <div className="flex gap-8 justify-between items-center">
        <div className="flex gap-8 justify-start items-center">
          <Button outlined type="button" color="black" label="Today" onClick={onClickToday} />
          <div className="inline-flex items-center gap-4">
            <div onClick={onClickPrev} className="cursor-pointer text-xl">
              <ArrowLeftIcon />
            </div>
            <div onClick={onClickNext} className="cursor-pointer text-xl">
              <ArrowRightIcon />
            </div>
          </div>
          <div className="inline-flex items-center gap-0">
            <InputDropdown
              plain
              light
              name="month"
              value={filters.month}
              onChange={onChangeField(PlannerFilters.MONTH)}
              items={months}
              renderInput={renderInput}
            />
            <InputDropdown
              plain
              light
              name="year"
              value={filters.year}
              onChange={onChangeField(PlannerFilters.YEAR)}
              items={years}
            />
          </div>
        </div>
        <div className="flex gap-4 justify-start items-center">
          <Button
            outlined
            type="button"
            color="black"
            label={filtersLabel}
            onClick={onClickFilters}
            endIcon={
              <span className={filtersIconClassName}>
                <ChevronRightIcon />
              </span>
            }
          />
          {!showSearch ? (
            <Button
              outlined
              type="button"
              color="black"
              onClick={onClickSearch}
              endIcon={
                <span className="inline-block -mx-2">
                  <SearchIcon />
                </span>
              }
            />
          ) : (
            <InputText
              light
              autoFocus
              name={PlannerFilters.SEARCH}
              value={filters.search}
              onBlur={onBlurSearch}
              startIcon={
                <span className="inline-block text-xl -my-px">
                  <SearchIcon />
                </span>
              }
              onChange={onChangeField(PlannerFilters.SEARCH)}
              placeholder="Search"
            />
          )}
          <Button
            type="button"
            color="primary"
            label="New event"
            onClick={onClickAdd}
            endIcon={<CalendarAddIcon />}
          />
        </div>
      </div>
      {showFilters && (
        <div className="mt-4">
          <PlannerCalendarFilters />
        </div>
      )}
    </div>
  );
};

export default PlannerTopBar;
