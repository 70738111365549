export const ChevronRightIcon = () => (
  <svg
    className="inline-block"
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1674_19281)">
      <path
        d="M13.75 10L7.5 16.25L6.625 15.375L12 10L6.625 4.625L7.5 3.75L13.75 10Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_1674_19281">
        <rect width="20" height="20" fill="currentColor" />
      </clipPath>
    </defs>
  </svg>
);

export default ChevronRightIcon;
