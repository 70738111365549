import { IPage } from '@/common/interfaces';
import { PATHS } from '@/common/path';
import {
  ContactPage,
  HomePage,
  ImpactPage,
  InstructorsPage,
  TrainingProgramsPage,
  WhoAreWePage,
  DigitalOfficePage,
  TrainingRequestPage,
  TrainingRequestsListPage,
  PlannerPage,
  TrainingProgramDetailPage,
  SignInPage,
  RequestTrainingFormPage,
  RequestTrainingFormSuccessPage,
  UserProfilePage,
  AnalyticsPage,
} from '../../pages';

export const DefaultMenuPages: Record<string, IPage> = {
  HOME: {
    component: HomePage,
    path: PATHS.home,
    slug: 'home-page',
    shouldLoadContent: true,
  },
  IMPACT: {
    component: ImpactPage,
    path: PATHS.impact,
    slug: 'impact-page',
    shouldLoadContent: true,
  },
  TRAININGS: {
    component: TrainingProgramsPage,
    path: PATHS.trainings,
    slug: 'trainings-page',
    shouldLoadContent: true,
  },
  INSTRUCTORS: {
    component: InstructorsPage,
    path: PATHS.instructors,
    slug: 'instructors-page',
    shouldLoadContent: true,
  },
  REQUEST_TRAINING_FORM: {
    component: RequestTrainingFormPage,
    path: PATHS.requestTraining,
    slug: 'request-training-page',
    shouldLoadContent: true,
  },
  CONTACT: {
    component: ContactPage,
    path: PATHS.contact,
    slug: 'contact-page',
  },
  PROGRAM_DETAILS: {
    component: TrainingProgramDetailPage,
    path: PATHS.trainingDetails,
    slug: 'trainings-program-detail-page',
  },
  REQUEST_TRAINING_SUCCESS: {
    component: RequestTrainingFormSuccessPage,
    path: PATHS.requestTrainingSuccess,
    slug: 'request-training-page',
    shouldLoadContent: true,
  },
  WHO_ARE_WE: {
    component: WhoAreWePage,
    path: PATHS.whoAreWe,
    slug: 'team-page',
    shouldLoadContent: true,
  },
  LOGIN: {
    component: SignInPage,
    path: PATHS.login,
    slug: 'login-page',
  },
};

// If no menu is needed, can use Breadcrumbs instead
export const NoMenuPages: Record<string, IPage> = {};

export const DigitalOfficePages: Record<string, IPage> = {
  DIGITAL_OFFICE: {
    component: DigitalOfficePage,
    path: PATHS.digitalOffice,
    slug: 'digital-office-page',
    shouldLoadContent: true,
  },
  TRAINING_REQUEST: {
    component: TrainingRequestPage,
    path: PATHS.digitalOfficeTrainingDetails,
    slug: 'digital-office-page',
    shouldLoadContent: true,
  },
  TRAINING_REQUEST_LIST: {
    component: TrainingRequestsListPage,
    path: PATHS.digitalOfficeTrainings,
    slug: 'training-requests',
  },
  PLANNER: {
    component: PlannerPage,
    path: PATHS.digitalOfficePlanner,
    slug: 'planner',
  },
  ANALYTICS: {
    component: AnalyticsPage,
    path: PATHS.digitalOfficeAnalytics,
    slug: 'analytics',
  },
};

export const UserMenuPages: Record<string, IPage> = {
  PROFILE: {
    component: UserProfilePage,
    path: PATHS.userProfile,
    slug: 'user-profile',
  },
};

export const Pages = {
  ...DefaultMenuPages,
  ...NoMenuPages,
  ...DigitalOfficePages,
  ...UserMenuPages,
};
