import { useSearchParams } from 'react-router-dom';
import { useCallback, useMemo } from 'react';

const useQueryFilters = function (
  fields: Record<string, string>,
  defaults?: Record<string, string | number>
) {
  const [searchParams, setSearchParams] = useSearchParams();

  const pageParams = ['limit', 'offset'];

  const filters = useMemo(
    () =>
      Object.values(fields).reduce(
        (ret: Partial<{ [key: string]: any }>, name: string) => {
          const value = searchParams.get(name);

          if (typeof value !== 'undefined' && value !== null) {
            ret[name] =
              value && pageParams.includes(name)
                ? parseInt(value)
                : (value as any);
          } else if (defaults && typeof defaults[name] !== 'undefined') {
            ret[name] = defaults[name] as any;
          }

          return ret;
        },
        {}
      ),
    [searchParams, fields, defaults]
  );

  const setFilter = useCallback(
    (name: string, value?: string | number) => {
      setSearchParams(
        (prev) => {
          typeof value !== 'undefined'
            ? prev.set(name, String(value))
            : prev.delete(name);

          if (name !== 'offset') {
            prev.delete('offset');
          }

          return prev;
        },
        {
          replace: true,
        }
      );
    },
    [setSearchParams, defaults]
  );

  const resetFilters = useCallback(() => {
    setSearchParams(
      (prev) => {
        Object.values(fields).forEach((param) => prev.delete(param));

        return prev;
      },
      {
        replace: true,
      }
    );
  }, [setSearchParams]);

  return {
    filters,
    setFilter,
    resetFilters,
  };
};

export default useQueryFilters;
