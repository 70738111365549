export const ArrowLeftIcon = () => (
  <svg
    className="inline-block"
    width="1em"
    height="1em"
    viewBox="0 0 21 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_898_3425)">
      <path
        d="M9.25 16.25L10.1438 15.3794L5.40625 10.625L18 10.625L18 9.375L5.40625 9.375L10.1437 4.64188L9.25 3.75L3 10L9.25 16.25Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_898_3425">
        <rect
          width="20"
          height="20"
          fill="currentColor"
          transform="translate(20.5 20) rotate(180)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default ArrowLeftIcon;
