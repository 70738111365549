const PlannerLegend = () => {
  return (
    <div className="inline-flex gap-2 items-center">
      <div className="text-sm leading-6 h-8 pl-3 pr-8 py-1 bg-blue-lighter border-l-4 border-dashed border-blue">
        Planned
      </div>
      <div className="text-sm leading-6 h-8 pl-3 pr-8 py-1 bg-green-do-light border-l-4 border-green-do">
        Confirmed
      </div>
      <div className="text-sm leading-6 h-8 pl-3 pr-8 py-1 bg-yellow-light border-l-4 border-yellow">
        Custom
      </div>
    </div>
  );
};

export default PlannerLegend;
