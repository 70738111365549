import {
  createContext,
  MouseEvent,
  ReactNode,
  RefObject,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import CalendarBooking from '../../../../interfaces/planner/CalendarBooking';
import CalendarEvent from '../../../../interfaces/planner/CalendarEvent';

type PlannerContextType = {
  openDetails: (e: MouseEvent, event: CalendarBooking | CalendarEvent) => void;
  openEditModal: (event: CalendarEvent) => void;
  closeDetails: () => void;
  closeEditModal: () => void;
  detailsRef?: RefObject<HTMLDivElement>;
  anchor: { x: 'left' | 'right'; y: 'center' | 'bottom' };
  currentEvent: CalendarBooking | CalendarEvent | null;
  editEvent: CalendarEvent | null;
  styles?: { left: number; top: number };
};

const PlannerContext = createContext<PlannerContextType>({
  openDetails: (e: MouseEvent, event: CalendarBooking | CalendarEvent) => {
    return;
  },
  closeDetails: () => {
    return;
  },
  openEditModal: (event: CalendarEvent) => {
    return;
  },
  closeEditModal: () => {
    return;
  },
  anchor: { x: 'left', y: 'center' },
  currentEvent: null,
  editEvent: null,
  styles: { left: 0, top: 0 },
});

const PlannerProvider = ({ children }: { children: ReactNode }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [currentEvent, setCurrentEvent] = useState<null | CalendarBooking | CalendarEvent>(null);
  const [editEvent, setEditEvent] = useState<null | CalendarEvent>(null);
  const [styles, setStyles] = useState({ left: 0, top: 0 });
  const [anchor, setAnchor] = useState<PlannerContextType['anchor']>({ x: 'left', y: 'center' });

  const detailsRef = useRef<HTMLDivElement | null>(null);

  const handlePosition = useCallback(() => {
    const anchorRect = anchorEl?.getBoundingClientRect();
    const detailsRect = detailsRef.current?.getBoundingClientRect();
    const anchorTop = anchorRect?.top || 0;
    const anchorLeft = anchorRect?.left || 0;
    const anchorHeight = anchorRect?.height || 0;
    const anchorWidth = anchorRect?.width || 0;
    const detailsHeight = detailsRect?.height || 0;
    const detailsWidth = detailsRect?.width || 0;
    const winHeight = window.innerHeight;
    const winWidth = window.innerWidth;

    const anchorCenterTop = anchorTop + anchorHeight / 2;
    const anchorCenterLeft = anchorLeft + anchorWidth / 2;

    let anchorPosition = {
      x: 'left',
      y: 'center',
    } as PlannerContextType['anchor'];

    let top = anchorCenterTop - detailsHeight / 2;
    let left = anchorCenterLeft;

    if (top + detailsHeight > winHeight) {
      top = anchorCenterTop - detailsHeight + 7;
      anchorPosition = { ...anchorPosition, y: 'bottom' };
    }

    if (left + detailsWidth > winWidth) {
      left = anchorCenterLeft - detailsWidth;
      anchorPosition = { ...anchorPosition, x: 'right' };
    }

    setAnchor(anchorPosition);
    setStyles({
      left: Math.max(Math.round(left), 0),
      top: Math.round(top),
    });
  }, [anchorEl]);

  const openDetails = useCallback(
    (e: MouseEvent, event: CalendarBooking | CalendarEvent) => {
      setAnchorEl(e.currentTarget as HTMLElement);
      setCurrentEvent(event);
      handlePosition();
    },
    [setAnchorEl, setCurrentEvent, handlePosition]
  );

  const closeDetails = useCallback(() => {
    setAnchorEl(null);
    setCurrentEvent(null);
  }, [setAnchorEl, setCurrentEvent]);

  const openEditModal = useCallback(
    (event: CalendarEvent) => {
      closeDetails();
      setEditEvent(event);
    },
    [setEditEvent, closeDetails]
  );

  const closeEditModal = useCallback(() => {
    setEditEvent(null);
  }, [setEditEvent]);

  const context = useMemo(
    () => ({
      openDetails,
      closeDetails,
      openEditModal,
      closeEditModal,
      currentEvent,
      editEvent,
      detailsRef,
      anchor,
      styles,
    }),
    [detailsRef, openDetails, closeDetails, openEditModal, closeEditModal, anchor, currentEvent, editEvent, styles]
  );

  useEffect(() => {
    handlePosition();
    window.addEventListener('resize', handlePosition);
    window.addEventListener('scroll', handlePosition);

    return () => {
      window.removeEventListener('resize', handlePosition);
      window.removeEventListener('scroll', handlePosition);
    };
  }, [handlePosition]);

  return <PlannerContext.Provider value={context}>{children}</PlannerContext.Provider>;
};

export const usePlannerCalendarContext = () => useContext(PlannerContext);

export default PlannerProvider;
