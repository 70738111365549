import { Button } from '@unbooking/ui-kit';
import { FC } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Pages } from '@/app/router/pages';
import { IBasePageComponent } from '@/common/interfaces';
import { getBgImageStyle } from '@/common/styles';
import Alert from '@/components/alert/Alert';

export const RequestTrainingFormSuccessPage: FC<IBasePageComponent> = ({ content }) => {
  const { requestId } = useParams() as { requestId: string };
  const navigate = useNavigate();
  return (
    <div
      style={getBgImageStyle(content.background_image_url)}
      className="flex justify-center items-start bg-no-repeat bg-cover w-full py-10 overflow-y-auto bg-fixed"
    >
      <div
        className="sm:w-full md:w-full lg:w-4/5 xl:w-3/5 flex flex-col items-center bg-training-details p-8 gap-8"
        data-testid="request-training-form-success-container"
      >
        <Alert
          alertType="success"
          titleText={`The request ${requestId} was successfully submitted!
                      You will be contacted once it has been reviewed.`}
        />
        <div className="max-w-[500px]">
          <Button label="Return to home page" onClick={() => navigate(Pages.HOME.path)} />
        </div>
      </div>
    </div>
  );
};

export default RequestTrainingFormSuccessPage;
