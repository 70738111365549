export const ListIcon = () => (
  <svg width="24" height="24" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.5 6C6.5 6.39782 6.34196 6.77936 6.06066 7.06066C5.77936 7.34196 5.39782 7.5 5 7.5C4.60218 7.5 4.22064 7.34196 3.93934 7.06066C3.65804 6.77936 3.5 6.39782 3.5 6C3.5 5.60218 3.65804 5.22064 3.93934 4.93934C4.22064 4.65804 4.60218 4.5 5 4.5C5.39782 4.5 5.77936 4.65804 6.06066 4.93934C6.34196 5.22064 6.5 5.60218 6.5 6ZM6.5 10C6.5 10.3978 6.34196 10.7794 6.06066 11.0607C5.77936 11.342 5.39782 11.5 5 11.5C4.60218 11.5 4.22064 11.342 3.93934 11.0607C3.65804 10.7794 3.5 10.3978 3.5 10C3.5 9.60218 3.65804 9.22064 3.93934 8.93934C4.22064 8.65804 4.60218 8.5 5 8.5C5.39782 8.5 5.77936 8.65804 6.06066 8.93934C6.34196 9.22064 6.5 9.60218 6.5 10ZM6.5 14C6.5 14.3978 6.34196 14.7794 6.06066 15.0607C5.77936 15.342 5.39782 15.5 5 15.5C4.60218 15.5 4.22064 15.342 3.93934 15.0607C3.65804 14.7794 3.5 14.3978 3.5 14C3.5 13.6022 3.65804 13.2206 3.93934 12.9393C4.22064 12.658 4.60218 12.5 5 12.5C5.39782 12.5 5.77936 12.658 6.06066 12.9393C6.34196 13.2206 6.5 13.6022 6.5 14Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.5 6C7.5 5.73478 7.60536 5.48043 7.79289 5.29289C7.98043 5.10536 8.23478 5 8.5 5H15.5C15.7652 5 16.0196 5.10536 16.2071 5.29289C16.3946 5.48043 16.5 5.73478 16.5 6C16.5 6.26522 16.3946 6.51957 16.2071 6.70711C16.0196 6.89464 15.7652 7 15.5 7H8.5C8.23478 7 7.98043 6.89464 7.79289 6.70711C7.60536 6.51957 7.5 6.26522 7.5 6ZM7.5 10C7.5 9.73478 7.60536 9.48043 7.79289 9.29289C7.98043 9.10536 8.23478 9 8.5 9H15.5C15.7652 9 16.0196 9.10536 16.2071 9.29289C16.3946 9.48043 16.5 9.73478 16.5 10C16.5 10.2652 16.3946 10.5196 16.2071 10.7071C16.0196 10.8946 15.7652 11 15.5 11H8.5C8.23478 11 7.98043 10.8946 7.79289 10.7071C7.60536 10.5196 7.5 10.2652 7.5 10ZM7.5 14C7.5 13.7348 7.60536 13.4804 7.79289 13.2929C7.98043 13.1054 8.23478 13 8.5 13H15.5C15.7652 13 16.0196 13.1054 16.2071 13.2929C16.3946 13.4804 16.5 13.7348 16.5 14C16.5 14.2652 16.3946 14.5196 16.2071 14.7071C16.0196 14.8946 15.7652 15 15.5 15H8.5C8.23478 15 7.98043 14.8946 7.79289 14.7071C7.60536 14.5196 7.5 14.2652 7.5 14Z"
      fill="white"
    />
  </svg>
);

export default ListIcon;
