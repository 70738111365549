export const CheckmarkDoneIcon = () => (
  <svg
    className="inline-block"
    width="1em"
    height="1em"
    viewBox="0 0 20 21"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_376_19415)">
      <path
        d="M8.75 13.8837L5.625 10.7581L6.50813 9.875L8.75 12.1163L13.4906 7.375L14.375 8.25937L8.75 13.8837Z"
        fill="green"
      />
      <path
        d="M10 1.75C8.26942 1.75 6.57769 2.26318 5.13876 3.22464C3.69983 4.1861 2.57832 5.55267 1.91606 7.15152C1.25379 8.75037 1.08051 10.5097 1.41813 12.207C1.75575 13.9044 2.58911 15.4635 3.81282 16.6872C5.03653 17.9109 6.59563 18.7442 8.29296 19.0819C9.9903 19.4195 11.7496 19.2462 13.3485 18.5839C14.9473 17.9217 16.3139 16.8002 17.2754 15.3612C18.2368 13.9223 18.75 12.2306 18.75 10.5C18.75 8.17936 17.8281 5.95376 16.1872 4.31282C14.5462 2.67187 12.3206 1.75 10 1.75ZM10 18C8.51664 18 7.0666 17.5601 5.83323 16.736C4.59986 15.9119 3.63856 14.7406 3.07091 13.3701C2.50325 11.9997 2.35473 10.4917 2.64411 9.03682C2.9335 7.58197 3.64781 6.24559 4.6967 5.1967C5.7456 4.14781 7.08197 3.4335 8.53683 3.14411C9.99168 2.85472 11.4997 3.00325 12.8701 3.5709C14.2406 4.13856 15.4119 5.09985 16.236 6.33322C17.0601 7.56659 17.5 9.01664 17.5 10.5C17.5 12.4891 16.7098 14.3968 15.3033 15.8033C13.8968 17.2098 11.9891 18 10 18Z"
        fill="green"
      />
    </g>
    <defs>
      <clipPath id="clip0_376_19415">
        <rect width="20" height="20" fill="currentColor" transform="translate(0 0.5)" />
      </clipPath>
    </defs>
  </svg>
);

export default CheckmarkDoneIcon;
