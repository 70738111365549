import { useMemo } from 'react';
import CalendarDay from '../../../../interfaces/planner/CalendarDay';
import PlannerCalendarDay from './PlannerCalendarDay';

type PlannerCalendarWeekProps = {
  weekDays: CalendarDay[];
};

const PlannerCalendarWeek = ({ weekDays }: PlannerCalendarWeekProps) => {
  const maxSlots = useMemo(() => {
    const weekSlots = weekDays.map((day) => {
      const daySlots = (day.events?.map((event) => event.slot) || [0]) as number[];
      return Math.max(...daySlots);
    });
    return Math.max(...weekSlots);
  }, [weekDays]);

  return (
    <div className="flex w-full">
      {weekDays.map((day: CalendarDay, index) => (
        <PlannerCalendarDay key={day.date.toISOString()} day={day} maxSlots={maxSlots} index={index} />
      ))}
    </div>
  );
};

export default PlannerCalendarWeek;
