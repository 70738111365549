import { apiDelete, apiGet, apiPatch, apiPost } from '../axios';
import CalendarEventBody from '@/interfaces/planner/CelandarEventBody';
import CalendarEventsParams from '@/interfaces/planner/CalendarEventsParams';
import { ListResponse } from '../interfaces';
import CalendarEventsItem from '@/interfaces/planner/CalendarEventsResponse';

// TO DO: Update API url

const postEvent = (data: CalendarEventBody) => apiPost<CalendarEventBody>('/events/', data);

const getEvents = (params: CalendarEventsParams) =>
  apiGet<ListResponse<CalendarEventsItem>>('/events/', params);

const updateEvent = (data: CalendarEventBody) =>
  apiPatch<CalendarEventBody>(`/events/${data.id}/`, data);

const deleteEvent = (id: string) => apiDelete<CalendarEventBody>(`/events/${id}/`);

export { postEvent, getEvents, updateEvent, deleteEvent };
