import * as yup from 'yup';
import dayjs from 'dayjs';
import { DATE_FORMAT } from '@/constants/generic';

const formatDate = (date?: string | Date) => (date ? dayjs(date).format(DATE_FORMAT) : '');

const formatDateMinMessage = ({ label, min }: { label: string; min: string | Date }) =>
  `${label} field must be on or later than ${formatDate(min)}`;

const PlannerEventModalSchema = function () {
  return yup
    .object()
    .shape({
      name: yup.string().label('Name').required(),
      eventStartDate: yup
        .date()
        .label('Start date')
        .transform(value => (dayjs(value).isValid() ? dayjs(value).toDate() : undefined))
        .required(),
      eventEndDate: yup
        .date()
        .label('End date')
        .transform(value => (dayjs(value).isValid() ? dayjs(value).toDate() : undefined))
        .when('eventStartDate', ([eventStartDate], schema, context) =>
          eventStartDate && dayjs(eventStartDate).isValid()
            ? schema
                .min(dayjs(eventStartDate).startOf('day').toDate(), formatDateMinMessage)
                .required()
            : schema.required(),
        ),
      remarks: yup.string().label('Remarks').required(),
      organizationName: yup.string().label('Organisation name (optional)'),
    })
    .required();
};

export default PlannerEventModalSchema;
