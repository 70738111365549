export const CarbonChart = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15 14V15H2C1.73478 15 1.48043 14.8946 1.29289 14.7071C1.10536 14.5196 1 14.2652 1 14V1H2V14H3.5V3H7.5L7.5 14H9.5V7H13.5V14H15ZM6.5 4H4.5V14H6.5V4ZM12.5 8H10.5V14H12.5V8Z"
      fill="white"
    />
  </svg>
);

export default CarbonChart;
