import React, { FC } from 'react';
import { Button } from '@unbooking/ui-kit';
import { useQuery } from '@tanstack/react-query';
import { Link } from 'react-router-dom';

import { DODashboardData, getDODashboardData } from '@/common/api/digitalOffice';
import { MenuIcon } from '@icons';
import { PATHS } from '@/common/path';
import { Spinner } from '@components';

interface INewRequests {
  newRequestsIds: string[];
}

export const NewRequests: FC<INewRequests> = ({ newRequestsIds }) => {
  return (
    <div className="flex flex-col bg-white text-black font-thin p-4 gap-3">
      <div className="text-5xl">{newRequestsIds.length}</div>
      <div className="font-bold">New training Requests</div>
      <div className="flex flex-col justify-start items-start mt-2 mb-6">
        {newRequestsIds.slice(0, 2).map(id => (
          <Link
            key={id}
            className="text-blue-400 font-medium"
            to={PATHS.digitalOfficeTrainingDetails.replace(':trainingRequestId', id)}
          >
            {id}
          </Link>
        ))}
      </div>
      <Link className="text-blue-400 font-medium" to={PATHS.digitalOfficeTrainings}>
        <Button label="View all training requests" startIcon={<MenuIcon />} />
      </Link>
    </div>
  );
};

export const DigitalOfficeDashboard: FC = () => {
  const { data, isError, isLoading } = useQuery<DODashboardData>({
    queryKey: ['dashboard-data'],
    queryFn: getDODashboardData,
  });

  return (
    <div className="flex flex-col justify-start gap-4">
      <div>Last 90 days overview</div>
      {isLoading ? (
        <div className="flex justify-center items-center w-full">
          <Spinner />
        </div>
      ) : isError ? (
        <div className="text-white">
          There was an unexpected error while loading the dashboard data.
        </div>
      ) : data ? (
        <div className="flex flex-row">
          <NewRequests newRequestsIds={data.new_training_requests_ids} />
        </div>
      ) : null}
    </div>
  );
};
