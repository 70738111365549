export const CircleStrokeIcon = () => (
  <svg
    className="inline-block"
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2787_70229)">
      <path
        d="M12.5 5.5C13.8845 5.5 15.2378 5.91054 16.389 6.67971C17.5401 7.44888 18.4373 8.54213 18.9672 9.82122C19.497 11.1003 19.6356 12.5078 19.3655 13.8656C19.0954 15.2235 18.4287 16.4708 17.4497 17.4497C16.4708 18.4287 15.2235 19.0954 13.8656 19.3655C12.5078 19.6356 11.1003 19.497 9.82122 18.9672C8.54214 18.4373 7.44889 17.5401 6.67972 16.389C5.91055 15.2378 5.5 13.8845 5.5 12.5C5.50209 10.6441 6.24026 8.86486 7.55256 7.55255C8.86486 6.24025 10.6441 5.50208 12.5 5.5ZM12.5 2C10.4233 2 8.39323 2.61581 6.66652 3.76957C4.9398 4.92332 3.59399 6.5632 2.79927 8.48182C2.00455 10.4004 1.79661 12.5116 2.20176 14.5484C2.6069 16.5852 3.60693 18.4562 5.07538 19.9246C6.54383 21.3931 8.41475 22.3931 10.4516 22.7982C12.4884 23.2034 14.5996 22.9955 16.5182 22.2007C18.4368 21.406 20.0767 20.0602 21.2304 18.3335C22.3842 16.6068 23 14.5767 23 12.5C23 9.71523 21.8938 7.04451 19.9246 5.07538C17.9555 3.10625 15.2848 2 12.5 2Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_2787_70229">
        <rect width="24" height="24" fill="currentColor" />
      </clipPath>
    </defs>
  </svg>
);

export default CircleStrokeIcon;
