import { weekDays } from '../../../../constants/dates';

const PlannerCalendarHead = () => {
  return (
    <div className="flex gap-px bg-gray-lightest">
      {weekDays.map((day) => (
        <div
          key={day}
          className="w-[14.285%] bg-white-light text-right py-1 px-3 font-normal border-t border-gray-lightest">
          {day}
        </div>
      ))}
    </div>
  );
};

export default PlannerCalendarHead;
