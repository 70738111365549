const Slots = function () {
  const slots = [] as string[];
  let removing = [] as number[];

  const addRemoving = (slot: number) => {
    removing.push(slot);
  };

  const remove = () => {
    removing.forEach((index) => (slots[index] = ''));
    removing = [];
  };

  const getSlot = (bookingId: string) => {
    let slotIndex = slots.findIndex((slot) => bookingId === slot);

    if (slotIndex === -1) {
      const emptySlotIndex = slots.findIndex((slot) => '' === slot);
      if (emptySlotIndex !== -1) {
        slots[emptySlotIndex] = bookingId;
        slotIndex = emptySlotIndex;
      } else {
        slots.push(bookingId);
        slotIndex = slots.length - 1;
      }
    }

    return slotIndex;
  };

  return {
    getSlot,
    addRemoving,
    remove,
  };
};

export default Slots;
