import { FC, useState } from 'react';

import { getDOStatuses } from '@/common/api/digitalOffice';
import { useQuery } from '@tanstack/react-query';
import AnalyticsPageTiles from './AnalyticsPageTiles';
import { getDOAnalytics } from '../../../common/api/digitalOffice';
import dayjs from 'dayjs';
import { getTrainingPrograms } from '@/common/api/trainings';
import { Spinner } from '@/components/common';
import AnalyticsPageFilters from './AnalyticsPageFilters';
import { DoughnutChart, InputDropdownItem } from '@unbooking/ui-kit';

import { mapToOptions, transformData } from './utils/utils';
import ProgressBarChart from '@/components/progressbar/ProgressbarChart';

export interface TrainingDetails {
  status: string;
  training_type: string;
  start_date: string;
  end_date: string;
}

export const AnalyticsPage: FC = () => {
  const [filters, setFilters] = useState<Partial<TrainingDetails>>({});
  const [rerenderKey, setRerenderKey] = useState(0);

  const { data: analyticsData, isLoading }: any = useQuery({
    queryKey: ['training-request-analytics', filters],
    queryFn: async () => {
      const analyticsData = await getDOAnalytics({ ...filters });

      return analyticsData;
    },
  });

  const { data: extraData, isLoading: isExtraDataLoading } = useQuery({
    queryKey: ['extra-data'],
    queryFn: async () => {
      const statuses = await getDOStatuses();
      const { data } = await getTrainingPrograms();

      const statusesOptions = mapToOptions(statuses, 'label', 'status');
      const trainingTypesOptions = mapToOptions(data?.results || [], 'title', 'training_type');

      return { statusesOptions, trainingTypesOptions };
    },
  });

  const onDatesSelect = (dateRange: string) => {
    const [dateFrom, dateTo] = dateRange.split(' - ');
    updateFilters('start_date', dayjs(dateTo).format('YYYY-MM-DD'));
    updateFilters('end_date', dayjs(dateFrom).format('YYYY-MM-DD'));
  };

  const clearFilters = () => {
    setFilters({} as Partial<TrainingDetails>);
    setRerenderKey(key => key + 1);
  };

  const updateFilters = (key: keyof any, value: string) => {
    setFilters((filter: Partial<TrainingDetails>) => ({
      ...filter,
      [key]: value,
    }));
  };

  return (
    <div className="w-full flex flex-col max-h-full bg-catskill-white m-5 p-5 overflow-y-auto gap-3 h-[calc(100vh-240px)]">
      <div className="flex flex-1 bg-no-repeat bg-cover bg-center flex-col">
        {(isLoading || isExtraDataLoading) && <Spinner fullScreen />}
        <h1 className="text-black-light text-3xl leading-10 font-normal">Analytics</h1>
        <div className="text-2xl font-light my-10">
          {analyticsData?.total_number_of_requests ?? 0} training requests
        </div>
        <AnalyticsPageFilters
          filters={filters}
          extraData={extraData}
          updateFilters={updateFilters}
          onDatesSelect={onDatesSelect}
          clearFilters={clearFilters}
          rerenderKey={rerenderKey}
        />
        <AnalyticsPageTiles analyticsData={analyticsData} />
        <div className="w-[100%] flex justify-around p-6 gap-6 bg-white-light">
          <div className="w-[30%]">
            <div className="mb-4 font-bold text-lg">Training types</div>
            <DoughnutChart
              total={analyticsData?.total_number_of_requests ?? 0}
              items={transformData(analyticsData?.training_types)}
              chartSize="200"
            />
          </div>
          <div className="w-[55%]">
            <ProgressBarChart title="Agencies" data={transformData(analyticsData?.agencies)} />
          </div>
        </div>
      </div>
    </div>
  );
};
