export const ArrowRightIcon = () => (
  <svg
    className="inline-block"
    width="1em"
    height="1em"
    viewBox="0 0 21 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_898_3433)">
      <path
        d="M11.75 3.75L10.8562 4.62063L15.5938 9.375H3V10.625H15.5938L10.8562 15.3581L11.75 16.25L18 10L11.75 3.75Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_898_3433">
        <rect
          width="20"
          height="20"
          fill="currentColor"
          transform="translate(0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default ArrowRightIcon;
