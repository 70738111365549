import cn from 'classnames';
import React, { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { Button } from '@unbooking/ui-kit';
import CalendarBooking from '../../../../interfaces/planner/CalendarBooking';
import CalendarEvent from '../../../../interfaces/planner/CalendarEvent';
import { usePlannerCalendarContext } from './PlannerContext';
import {
  CalendarIcon,
  ChatIcon,
  EditIcon,
  EventsIcon,
  HotelIcon,
  TrashIcon,
  UserIcon,
} from '@/assets/icons';
import { PATHS } from '@/common/path';
import { useMutation } from '@tanstack/react-query';
import { deleteEvent } from '@/common/api/events';
import { toast } from 'react-toastify';

type PlannerCalendarDetailProps = {
  event: CalendarBooking | CalendarEvent;
};

const PlannerCalendarDetail = ({ event }: PlannerCalendarDetailProps) => {
  const navigate = useNavigate();
  const { closeDetails, openEditModal } = usePlannerCalendarContext();

  const deleteEventMutation = useMutation({
    mutationFn: deleteEvent,
    onSuccess: () => {
      toast.success('Event successfully deleted');
      closeDetails();
    },
  });

  const statusClassName = useMemo(
    () =>
      cn('pl-3 pr-8 py-1 border-l-4', {
        'bg-primary-lighter border-dashed border-primary': event.type === 'booking',
        'bg-green-do-light border-green-do': event.type === 'booking',
        'bg-yellow-light border-yellow': event.type === 'custom',
      }),
    [event],
  );

  const statusLabel = 'Event';

  const items = useMemo(
    () => [
      {
        icon: <CalendarIcon />,
        value: [
          event.fromDate?.format('ddd DD/MM/YYYY'),
          event.toDate?.format('ddd DD/MM/YYYY'),
        ].join(' - '),
      },
      ...(event.type === 'booking'
        ? [
            {
              icon: <UserIcon />,
              value: [event.requester, event.agency?.toUpperCase()].join(' - '),
            },
            {
              icon: <EventsIcon />,
              value: event.eventType,
            },
            {
              icon: <HotelIcon />,
              value: event.lodgingType,
            },
            ...(event.eventName
              ? [
                  {
                    icon: <ChatIcon />,
                    value: event.eventName,
                  },
                ]
              : []),
          ]
        : [
            {
              icon: <ChatIcon />,
              value: event.remarks,
            },
            {
              icon: <ChatIcon />,
              value: event.name,
            },
            ...(event.organizationName
              ? [
                  {
                    icon: <UserIcon />,
                    value: event.organizationName,
                  },
                ]
              : []),
          ]),
    ],
    [event],
  );

  const handleEdit = useCallback(() => {
    // Note: Navigate is temporary, should be refactored for current app
    event.type === 'custom' ? openEditModal(event) : navigate(PATHS.digitalOfficeTrainings);
  }, [event, navigate, openEditModal]);

  const handleDelete = () => {
    if (event.type !== 'custom') return;
    deleteEventMutation.mutate(event.id);
  };

  return (
    <div className="p-6 shadow-md shadow-gray-light bg-white-light border border-gray-lighter">
      <div className="flex flex-col gap-3">
        <div className={statusClassName}>{statusLabel}</div>
        <div className="flex flex-col">
          {items.map((item, index) => (
            <div
              key={index}
              className="flex gap-3 text-gray-darker items-center py-3 border-b border-gray-lighter"
            >
              <div className="text-lg">{item.icon}</div>
              <div className="text-sm">{item.value}</div>
            </div>
          ))}
        </div>
        <div className="flex items-stretch gap-4 w-full pt-6">
          <Button
            outlined
            fullWidth
            color="black"
            label="Edit"
            endIcon={<EditIcon />}
            onClick={handleEdit}
          />
          {event.type === 'custom' && (
            <Button
              outlined
              fullWidth
              color="black"
              label="Delete"
              endIcon={<TrashIcon />}
              onClick={handleDelete}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default PlannerCalendarDetail;
